import styled from "styled-components";
import { layout } from "src/styles/_breakpoints";
import { Button } from "@progress/kendo-react-buttons";

interface ConfirmModalProps {
  handleClose: () => void;
  handleConfirm: () => void;
}

const ConfirmModal = ({ handleClose, handleConfirm }: ConfirmModalProps) => {
  return (
    <ButtonContainer>
      <Button themeColor="secondary" fillMode="outline" onClick={handleClose}>
        Cancel
      </Button>
      <Button themeColor="primary" onClick={handleConfirm}>
        Confirm
      </Button>
    </ButtonContainer>
  );
};
export default ConfirmModal;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding-top: 16px;
  @media ${layout.mobile} {
    padding: 20px;
    > button {
      width: 50%;
    }
  }
`;
