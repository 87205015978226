import { useEffect, useState } from "react";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
} from "@progress/kendo-react-upload";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { downloadLightIcon } from "@progress/kendo-svg-icons";
import { Typography } from "@progress/kendo-react-common";
import styled from "styled-components";
import { DROPDOWN_CODES, ApiEndpoints } from "src/utils/constants";
import {
  useGetCodeQuery,
  useUploadFileMutation,
} from "src/Redux/Slice/Api/QuerySlice";
import { downloadFile } from "src/utils/fileDownload";
import { UploadErrorResponse, UploadResponse } from "./type";
import { layout } from "src/styles/_breakpoints";
import DialogBox from "../dialogBox/dialogBox";
import { ErrorListDialog } from "../shared/errorListDialog/errorListDialog";
import useScreenSize from "src/hooks/useScreenSize";
import FileUploadSuccess from "./FileUploadSuccess";
import { color } from "src/styles/_colors";
import { useErrorContext } from "src/contexts/ErrorContext";
import { ERROR_TOAST_MESSAGE } from "src/constants/SharedConstant";
import Instructions from "./instructions/instructions";
import { Heading18 } from "src/styles/_typography";
import { LoaderSection } from "components/loader/loader";

const FILE_SIZE = 4 * 1048576; //4MB

const FileList = (files: Array<UploadFileInfo>) => {
  return (
    <ul>
      {files.map((file: UploadFileInfo) => (
        <li key={file.name}>{file.name}</li>
      ))}
    </ul>
  );
};

export const CreateRequest = () => {
  const [uploadFile, { isLoading, reset }] = useUploadFileMutation();

  const { isDesktop } = useScreenSize();
  const { setErrorMessage } = useErrorContext();

  const {
    data: radioOptionData,
    error,
    isFetching: isRadioOptionFetching,
  } = useGetCodeQuery(DROPDOWN_CODES.CD_ItemType);

  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const [uploadStatus, setUploadStatus] = useState<UploadResponse | null>(null);
  const [selectedOption, setSelectedOption] = useState<{
    name: string;
    id: string;
  } | null>(null);

  useEffect(() => {
    if (error) {
      setErrorMessage(ERROR_TOAST_MESSAGE);
      throw error;
    }
  }, [error, setErrorMessage]);

  const onAdd = async (event: UploadOnAddEvent) => {
    const file = event?.affectedFiles[0];
    if (!file.getRawFile) return;

    setFiles(event.newState);

    const formData = new FormData();
    formData.append("file", file?.getRawFile());

    try {
      const data = await uploadFile(formData).unwrap();
      setUploadStatus(data);
    } catch (err) {
      setUploadStatus((err as UploadErrorResponse).data);
    }
  };

  const onClose = () => {
    reset();
    setUploadStatus(null);
    setFiles([]);
  };

  return (
    <RootContainer>
      <Instructions />
      <RadioOptionsWrapper>
        <RadioOptionsContainer>
          <RadioOptionsOptions>
            <RadioOptionsHeader>Select the Item Type:</RadioOptionsHeader>
            <OptionSection>
              {isRadioOptionFetching && <StyledLoader size="medium" />}
              {radioOptionData?.results.map((option) => {
                const idStr = String(option.id);
                return (
                  <div key={idStr}>
                    <RadioButton
                      name="item-type"
                      value={idStr}
                      checked={idStr === selectedOption?.id}
                      label={option.name}
                      onChange={() => {
                        setSelectedOption({ id: idStr, name: option.name });
                      }}
                    />
                  </div>
                );
              })}
            </OptionSection>
            <Button
              themeColor="primary"
              svgIcon={downloadLightIcon}
              disabled={!selectedOption?.id}
              onClick={() => {
                downloadFile(
                  `/${ApiEndpoints.itemTemplate(selectedOption?.id as string)}`,
                  selectedOption?.name as string
                );
              }}
            >
              Spreadsheet Download
            </Button>
          </RadioOptionsOptions>
          <UploadContainer>
            <StyledUpload
              restrictions={{
                allowedExtensions: [".xlsx"],
                maxFileSize: FILE_SIZE,
              }}
              withCredentials={false}
              onAdd={onAdd}
            />
          </UploadContainer>
        </RadioOptionsContainer>
      </RadioOptionsWrapper>

      {isLoading && <StyledP>Uploading {FileList(files)}</StyledP>}

      <DialogBox
        isOpen={uploadStatus?.isSuccess === true}
        width={isDesktop ? "550px" : "100%"}
        height={isDesktop ? "50%" : "100%"}
        title="Success"
        onClose={onClose}
      >
        {uploadStatus?.isSuccess === true && (
          <FileUploadSuccess uploadStatus={uploadStatus} onClose={onClose} />
        )}
      </DialogBox>

      <DialogBox
        isOpen={uploadStatus?.isSuccess === false}
        width={isDesktop ? "550px" : "100%"}
        height={isDesktop ? "50%" : "100%"}
        title="Error"
        onClose={onClose}
      >
        <ErrorListDialog errors={uploadStatus?.errors as string[]} />
      </DialogBox>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  @media ${layout.tabletS}, ${layout.mobile} {
    max-width: 100vw;
    overflow-x: hidden;
  }
`;

const RadioOptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px 0;
`;

const RadioOptionsContainer = styled.div`
  display: flex;
  margin: 0 48px;
  flex-grow: 1;
  flex-basis: 0;
  border-radius: 14px;
  max-width: 1344px;
  gap: 24px;

  @media ${layout.tabletS}, ${layout.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 0;
    gap: 10px;
  }
`;

const RadioOptionsOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  flex-basis: 100%;
  background-color: ${color.white};
  border-radius: 16px;
  align-items: flex-start;
  gap: 16px;

  @media ${layout.tabletS}, ${layout.mobile} {
    padding: 80px;
    margin-bottom: 20px;
    width: 90vw;
  }
`;

const RadioOptionsHeader = styled.div`
  ${Heading18}
`;

const OptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: start;
  width: 100%;
`;

const StyledLoader = styled(LoaderSection)`
  height: 152px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  width: 100%;
  position: relative;

  @media ${layout.tabletS}, ${layout.mobile} {
    width: 95vw;
    padding: 100px 0;
  }
`;

const StyledUpload = styled(Upload)`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color: ${color.gray100};
  border: none;

  > * {
    &:first-child {
      background-color: ${color.gray96};
      border: 8px double ${color.gray90};
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      > * {
        &:last-child {
          flex: none;
        }
      }
    }
  }
`;

const StyledP = styled(Typography.p)``;
