import { Typography } from "@progress/kendo-react-common";
import { color } from "src/styles/_colors";
import { Heading24, Body16 } from "src/styles/_typography";
import styled from "styled-components";
import ErrorIcon from "src/images/error.svg";
import { Button } from "@progress/kendo-react-buttons";

interface UnAuthenticatedModalProps {
  handleLogin: () => void;
}

const UnAuthenticatedModal: React.FC<UnAuthenticatedModalProps> = ({
  handleLogin,
}) => {
  return (
    <div>
      <div>
        <img src={ErrorIcon} alt="error icon" />
        <UnAuthenticatedModalHeader>
          Oops! You're Not Logged In{" "}
        </UnAuthenticatedModalHeader>
        <UnAuthenticatedModalText>
          Please click the button below to continue.
        </UnAuthenticatedModalText>
      </div>
      <LineDivider />
      <UnAuthenticatedModalButton>
        <Button themeColor="primary" onClick={handleLogin}>
          Log in
        </Button>
      </UnAuthenticatedModalButton>
    </div>
  );
};

export default UnAuthenticatedModal;

const UnAuthenticatedModalHeader = styled(Typography.h2)`
  ${Heading24};
  margin-top: 16px;
`;

const UnAuthenticatedModalText = styled(Typography.p)`
  ${Body16};
  color: ${color.gray50};
  font-weight: 400;
`;

const UnAuthenticatedModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

export const LineDivider = styled.div`
  width: 100%;
  border: 1px solid ${color.gray96};
`;
