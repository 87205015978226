import styled from "styled-components";
import {
  R_ITEM_HEADER,
  UPDATE_PAGE_FILTERS,
  UPDATE_WH_ITEM_FIELDS,
  UPDATE_R_ITEM_FIELDS,
  WH_ITEM_HEADER,
  ITEM_REQUEST,
} from "src/utils/constants";
import { TextBox, InputPrefix } from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import { searchIcon } from "@progress/kendo-svg-icons";
import {
  useLazyGetRetailItemsQuery,
  useLazyGetWarehouseItemsQuery,
} from "src/Redux/Slice/Api/QuerySlice";
import { ERROR_TOAST_MESSAGE } from "src/constants/SharedConstant";
import { useErrorContext } from "src/contexts/ErrorContext";
import { FormEvent, useEffect, useState } from "react";
import Table from "../shared/Table/Table";
import {
  PageState,
  WhItemResponse,
  RItemResponse,
} from "../../interfaces/types";
import { Typography } from "@progress/kendo-react-common";
import {
  GridItemChangeEvent,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import {
  addRItem,
  addWhItem,
  removeRItem,
  removeWhItem,
} from "src/Redux/Slice/Api/updatedGridSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/Redux/store";
import SelectDropdown from "../shared/selectDropdown/selectDropdown";
import { layout } from "src/styles/_breakpoints";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

export const UpdateRequest = () => {
  const dispatch = useDispatch();
  const whItemsStore = useSelector(
    (state: RootState) => state.updateRequest.whItems
  );
  const rItemsStore = useSelector(
    (state: RootState) => state.updateRequest.rItems
  );

  const { setErrorMessage } = useErrorContext();
  const [searchFilter, setSearchFilter] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [itemType, setItemType] = useState("");

  const [
    searchWhItems,
    { data: whItems, isFetching: isLoadingWhitems, error: whError },
  ] = useLazyGetWarehouseItemsQuery();
  const [
    searchRItems,
    { data: rItems, isFetching: isLoadingRitems, error: rError },
  ] = useLazyGetRetailItemsQuery();

  useEffect(() => {
    if (whError || rError) {
      setErrorMessage(ERROR_TOAST_MESSAGE);
      throw whError ? whError : rError;
    }
  }, [whError, rError, setErrorMessage]);

  const [whData, setWhData] = useState<WhItemResponse[]>([]);

  const [rData, setRData] = useState<RItemResponse[]>([]);

  useEffect(() => {
    if (itemType === "warehouse" && whItems) {
      setWhData(whItems.results);
    }
    if (itemType === "retail" && rItems) {
      setRData(rItems.results);
    }
  }, [whItems, rItems, itemType]);

  const rowWhClick = (event: GridRowClickEvent) => {
    const selectedItemId = event.dataItem.whitem;
    const updatedData = whData?.map((item: WhItemResponse) => ({
      ...item,
      inEdit: item.whitem === selectedItemId,
    }));
    setWhData(updatedData);
  };

  const rowRClick = (event: GridRowClickEvent) => {
    const selectedItemId = event.dataItem.rItem;
    const updatedData = rData?.map((item: RItemResponse) => ({
      ...item,
      inEdit: item.rItem === selectedItemId,
    }));
    setRData(updatedData);
  };

  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (itemType === "warehouse") {
      searchWhItems({
        [searchFilter]: searchValue,
      });
    } else if (itemType === "retail") {
      searchRItems({
        [searchFilter]: searchValue,
      });
    }
  };

  const itemWhChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.whitem;
    const field = event.field || "";
    const newData = whData?.map((item: WhItemResponse) => {
      if (item.whitem === inEditID) {
        const updatedItem = { ...item, [field]: event.value };
        const hasInEditID = whItemsStore.some(
          (item) => item.whitem === inEditID
        );
        if (hasInEditID) {
          dispatch(removeWhItem(inEditID));
        }
        dispatch(addWhItem(updatedItem));

        return updatedItem;
      }
      return item;
    });
    setWhData(newData);
  };

  const itemRChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.rItem;
    const field = event.field || "";
    const newData = rData?.map((item: RItemResponse) => {
      if (item.rItem === inEditID) {
        const updatedItem = { ...item, [field]: event.value };
        const hasInEditID = rItemsStore.some((item) => item.rItem === inEditID);
        if (hasInEditID) {
          dispatch(removeRItem(inEditID));
        }
        dispatch(addRItem(updatedItem));

        return updatedItem;
      }
      return item;
    });
    setRData(newData);
  };

  const initialPageState: PageState = { skip: 0, take: 100 };

  const initialPageSortWhItem = [
    {
      field: WH_ITEM_HEADER,
      dir: "asc" as const,
    },
  ];

  const initialPageSortRItem = [
    {
      field: R_ITEM_HEADER,
      dir: "asc" as const,
    },
  ];

  const handleUndo = (type: "wh" | "r") => {
    if (type === "wh" && whItems) {
      setWhData(whItems.results); // Revert only warehouse items
    } else if (type === "r" && rItems) {
      setRData(rItems.results); // Revert only retail items
    }
  };

  const handleItemChange = (e: DropDownListChangeEvent) => {
    setWhData([]);
    setRData([]);
    setItemType(e.target.value?.value);
  };

  return (
    <div role="main" aria-label="Update Request">
      <form onSubmit={handleSearch}>
        <StyledContainer>
          <SelectDropdown
            dropdowndata={ITEM_REQUEST}
            id="item-filter"
            placeholder={{ name: "Select Item" }}
            style={{ width: "200px" }}
            onChange={handleItemChange}
          />
          <SelectDropdown
            dropdowndata={UPDATE_PAGE_FILTERS}
            id="page-filter"
            placeholder={{ name: "Select Search Filter" }}
            style={{ width: "200px" }}
            onChange={(e) => setSearchFilter(e.target.value?.value)}
            disabled={!itemType}
          />
          <TextBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value as string)}
            disabled={!searchFilter}
            prefix={() => (
              <InputPrefix>
                <SvgIcon icon={searchIcon} />
              </InputPrefix>
            )}
            placeholder="Search Products"
          />
          <Button
            themeColor="primary"
            type="submit"
            svgIcon={searchIcon}
            disabled={searchValue.length < 4}
          >
            Search
          </Button>
        </StyledContainer>
      </form>
      <TableContainer>
        {/* Ware house Items */}
        <Table
          data={whItems}
          isLoading={isLoadingWhitems}
          error={whError !== undefined}
          reqFields={UPDATE_WH_ITEM_FIELDS}
          initialPageState={initialPageState}
          initialPageSort={initialPageSortWhItem}
          exportFileName="GEWarehouseItems"
          onRowClick={rowWhClick}
          onItemChange={itemWhChange}
          updatedData={whData}
          onUndo={() => handleUndo("wh")}
        />
        {whItems?.results?.length === 0 && !isLoadingWhitems && (
          <ErrorWrapper>
            <Typography.p>No wholesale items found</Typography.p>
          </ErrorWrapper>
        )}

        {/* Retail Items */}
        <Table
          data={rItems}
          isLoading={isLoadingRitems}
          error={rError !== undefined}
          reqFields={UPDATE_R_ITEM_FIELDS}
          initialPageState={initialPageState}
          initialPageSort={initialPageSortRItem}
          exportFileName="GeRetailItems"
          onRowClick={rowRClick}
          onItemChange={itemRChange}
          updatedData={rData}
          onUndo={() => handleUndo("r")}
        />
        {rItems?.results?.length === 0 && !isLoadingRitems && (
          <ErrorWrapper>
            <Typography.p>No retail items found</Typography.p>
          </ErrorWrapper>
        )}
      </TableContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 48px;
  gap: 1rem;
  @media ${layout.mobile}, ${layout.tablet} {
    flex-direction: column;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 16px;
`;

const ErrorWrapper = styled.div`
  margin-top: 50px;
  color: red;
`;
