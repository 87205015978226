import styled from "styled-components";
import { UploadResponse } from "./type";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { Link } from "react-router-dom";
import { color } from "src/styles/_colors";

const FileUploadSuccess = ({
  uploadStatus,
  onClose,
}: {
  uploadStatus: UploadResponse;
  onClose: () => void;
}) => {
  return (
    <>
      <StyledMessage>{uploadStatus?.message}</StyledMessage>
      <StyledP>Request No: {uploadStatus?.results[0]?.requestNumber}</StyledP>

      <ButtonGroup>
        <Button themeColor="primary" onClick={onClose}>
          Create Another Request
        </Button>

        <StyledSecondaryLink to="/">Back to Dashboard</StyledSecondaryLink>
      </ButtonGroup>
    </>
  );
};

export default FileUploadSuccess;

const StyledMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const StyledP = styled(Typography.p)``;

const StyledSecondaryLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 999px;
  font-size: 16px;
  padding: 12px 48px;
  color: #2c3337;
  background-color: ${color.white};
  border: 1px solid ${color.gray90};

  &:hover {
    background-color: ${color.gray100};
  }
`;
