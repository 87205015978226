export const IND_API_DOMAIN = import.meta.env.VITE_API_DOMAIN_IND as string;
export const OP_API_DOMAIN = import.meta.env.VITE_API_DOMAIN_OP as string;

import { ColumnField } from "src/components/shared/Table/type";

export const ApiEndpoints = {
  dashboard: "/api/Item/GetRequests",
  code: (id: number) => `/api/Item/GetCodes/${id}`,
  upload: "/api/Item/uploadSpreadsheet",
  itemTemplate: (id: number | string) => `api/Item/DownloadItemTemplate/${id}`,
  getWarehouseItems: "/api/item/getwarehouseitems",
  getRetailItems: "/api/item/getretailitems",
};

export const TransactionLogId = "transactionLogId";

export const UNIQUEHEADERSTRING = "requestNumber";

export const R_ITEM_HEADER = "rItem";

export const WH_ITEM_HEADER = "whitem";

export const FILE_NAME_PREPEND = "GE_NIF_";

export const DROPDOWN_CODES = {
  CD_AllowanceType: 1,
  CD_CostType: 2,
  CD_ItemProperty: 3,
  CD_ItemType: 4,
  CD_LineOfBusiness: 5,
  CD_OrderRestriction: 6,
  CD_OwnBrand: 7,
  CD_Priority: 8,
  CD_ProductType: 9,
  CD_Reason: 10,
  CD_TagPrint: 11,
  CD_UnitOfMeasurement: 12,
  CD_UPCFormat: 13,
};

export const DASHBOARDFIELDS: ColumnField[] = [
  {
    field: "requestNumber",
    headerName: "Request #",
    locked: true,
    editable: false,
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "itemType",
    headerName: "Request Type",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "lineOfBusiness",
    headerName: "LOB",
    locked: false,
    editable: false,
    width: 100,
  },
  {
    field: "requestDescription",
    headerName: "Request Desc",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "supplierNumbers",
    headerName: "Supplier #",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "supplierName",
    headerName: "Supplier Name",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "vendorNumber",
    headerName: "Vendor #",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "vendorName",
    headerName: "Vendor Name",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "contactName",
    headerName: "Contact Name",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "contactEmail",
    headerName: "Contact Email",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "contactPhone",
    headerName: "Contact Phone",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "createdOn",
    headerName: "Created",
    locked: false,
    editable: false,
    width: 100,
    customFields: {
      fieldType: "date",
      isShown: true,
    },
  },
  {
    field: "modifiedOn",
    headerName: "Modified",
    locked: false,
    editable: false,
    width: 150,
    customFields: {
      fieldType: "date",
      isShown: true,
    },
  },
  {
    field: "modifiedBy",
    headerName: "Modfied By",
    locked: false,
    editable: false,
    width: 150,
  },
];

export const UPDATE_WH_ITEM_FIELDS: ColumnField[] = [
  {
    field: "whitem",
    headerName: "WItem #",
    locked: true,
    editable: false,
    width: 150,
  },
  {
    field: "rItem",
    headerName: "RItem #",
    locked: true,
    editable: false,
    width: 150,
  },
  {
    field: "upc",
    headerName: "UPC",
    locked: true,
    editable: true,
    width: 150,
  },
  {
    field: "upcType",
    headerName: "UPC Type",
    locked: true,
    editable: true,
    width: 150,
    customFields: {
      fieldType: "dropdown",
      isShown: true,
      code: DROPDOWN_CODES.CD_UPCFormat,
    },
  },
  {
    field: "itemDescription",
    headerName: "Item Desc",
    locked: true,
    editable: false,
    width: 300,
  },
  {
    field: "rpSupplierNumber",
    headerName: "Supplier # ",
    locked: true,
    editable: true,
    width: 150,
  },
  {
    field: "authorizationTemplate",
    headerName: "Auth Template",
    locked: true,
    editable: false,
    width: 150,
  },
  {
    field: "fullDescription",
    headerName: "Full Desc",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "packCaseQuantity",
    headerName: "Pack Case Qty",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "unitsPerInnerPack",
    headerName: "Units per Inner pack",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },

  {
    field: "caseLengthInches",
    headerName: "Case Length (in)",
    locked: false,
    editable: true,
    width: 150,
  },

  {
    field: "caseWidthInches",
    headerName: "Case Width (in)",
    locked: false,
    editable: true,
    width: 150,
  },

  {
    field: "caseHeightInches",
    headerName: "Case Height (in)",
    locked: false,
    editable: true,
    width: 150,
  },

  {
    field: "caseWeightLbs",
    headerName: "Case Weight (In)",
    locked: false,
    editable: true,
    width: 150,
  },

  {
    field: "caseCube",
    headerName: "Case Cube",
    locked: false,
    editable: true,
    width: 150,
  },

  {
    field: "cubeAdjustedWeight",
    headerName: "Cube Adj Weight",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "tie",
    headerName: "Tie",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "tier",
    headerName: "Tier",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "packCaseCost",
    headerName: "Pack Case Cost",
    locked: false,
    editable: true,
    width: 150,
  },

  {
    field: "bicepS02ShelfLife",
    headerName: "BICEPS02 Shelf Life",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "created",
    headerName: "Created",
    locked: false,
    editable: false,
    width: 150,
    customFields: {
      fieldType: "date",
      isShown: true,
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "modified",
    headerName: "Modified",
    locked: false,
    editable: false,
    width: 150,
    customFields: {
      fieldType: "date",
      isShown: true,
    },
  },
  {
    field: "modifiedBy",
    headerName: "Modified By",
    locked: false,
    editable: false,
    width: 150,
  },
];

export const UPDATE_R_ITEM_FIELDS: ColumnField[] = [
  {
    field: "rItem",
    headerName: "RItem #",
    locked: true,
    editable: false,
    width: 150,
  },
  {
    field: "whitem",
    headerName: "WItem #",
    locked: true,
    editable: false,
    width: 150,
  },
  {
    field: "upc",
    headerName: "UPC",
    locked: true,
    editable: true,
    width: 150,
  },
  {
    field: "upcType",
    headerName: "UPC Type",
    locked: true,
    editable: true,
    customFields: {
      fieldType: "dropdown",
      isShown: true,
      code: DROPDOWN_CODES.CD_UPCFormat,
    },
    width: 150,
  },
  {
    field: "itemDescription",
    headerName: "Item Desc",
    locked: true,
    editable: false,
    width: 300,
  },
  {
    field: "rpSupplierNumber",
    headerName: "Supplier #",
    locked: true,
    editable: true,
    width: 150,
  },
  {
    field: "authorizationTemplate",
    headerName: "Auth Template",
    locked: true,
    editable: false,
    width: 150,
  },
  {
    field: "brand",
    headerName: "Brand",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "fullDescription",
    headerName: "Full Desc",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "posDescription",
    headerName: "POS Desc",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "shelfTagDescriptionLine1",
    headerName: "Shelf Tag Line 1",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "shelfTagDescriptionLine2",
    headerName: "Shelf Tag Line 2",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "size",
    headerName: "Size",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "minimumOrderQuantity",
    headerName: "MOQ",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "minimumProductionQuantity",
    headerName: "MPQ",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "unitCost",
    headerName: "Unit Cost",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "unitAllowance",
    headerName: "Unit Allowance",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "slottingFee",
    headerName: "Slotting Fee",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "suggestedRetailPrice",
    headerName: "Suggested Retail Pr",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "retailPrice",
    headerName: "Retail Pr",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "retailMarginCalculated",
    headerName: "Retail Margin Calc",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "unitLengthInches",
    headerName: "Unit Length (in)",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "unitWidthInches",
    headerName: "Unit Width (in)",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "unitHeightInches",
    headerName: "Unit Height (in)",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "unitWeightLbs",
    headerName: "Unit Weight (in)",
    locked: false,
    editable: true,
    width: 150,
  },
  {
    field: "retailShelfLife",
    headerName: "Retail Shelf Life",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "catchWeight",
    headerName: "Catch Weight",
    locked: false,
    editable: true,
    editor: "numeric",
    width: 150,
    customFields: {
      fieldType: "numeric",
      isShown: true,
    },
  },
  {
    field: "created",
    headerName: "Created",
    locked: false,
    editable: false,
    width: 150,
    customFields: {
      fieldType: "date",
      isShown: true,
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    locked: false,
    editable: false,
    width: 150,
  },
  {
    field: "modified",
    headerName: "Modified",
    locked: false,
    editable: false,
    width: 150,
    customFields: {
      fieldType: "date",
      isShown: true,
    },
  },
  {
    field: "modifiedBy",
    headerName: "Modfied By",
    locked: false,
    editable: false,
    width: 150,
  },
];

export const UPDATE_PAGE_FILTERS = [
  {
    name: "UPC",
    value: "UPC",
  },
  {
    name: "Retail Item #",
    value: "rItem",
  },
  {
    name: "WITEM #",
    value: "whItem",
  },
  {
    name: "Supplier #",
    value: "RpSupplierNumber",
  },
  {
    name: "Item Description",
    value: "ItemDescription",
  },
];

export const ITEM_REQUEST = [
  { name: "Retail Items", value: "retail" },
  { name: "Warehouse Items", value: "warehouse" },
];
