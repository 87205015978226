import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useGetCodeQuery } from "src/Redux/Slice/Api/QuerySlice";
import { Typography } from "@progress/kendo-react-common";
import styled from "styled-components";
import { useErrorContext } from "src/contexts/ErrorContext";
import { useEffect, useState } from "react";
import { ERROR_TOAST_MESSAGE } from "src/constants/SharedConstant";
import { CodeResults, SelectDropdownProps, UpdateDropdownType } from "./types";

const SelectDropdown = ({
  code,
  placeholder,
  textField = "name",
  dropdowndata,
  onChange,
  ...props
}: SelectDropdownProps) => {
  const { setErrorMessage } = useErrorContext();

  const {
    data: fetchedData,
    error,
    isLoading,
  } = useGetCodeQuery(code as number, {
    skip: !code,
  });

  const [data, setData] = useState<
    CodeResults[] | UpdateDropdownType[] | undefined
  >([]);
  const [option, setOption] = useState(placeholder);

  useEffect(() => {
    if (code && fetchedData) {
      setData(fetchedData.results);
    } else {
      setData(dropdowndata);
    }
  }, [code, fetchedData, dropdowndata]);

  useEffect(() => {
    if (error) {
      setErrorMessage(ERROR_TOAST_MESSAGE);
      throw error;
    }
  }, [error, setErrorMessage]);

  const handleChange = (e: DropDownListChangeEvent) => {
    setOption(e.value);
    onChange && onChange(e);
  };

  return (
    <div>
      <DropDownList
        loading={isLoading}
        data={data || []}
        textField={textField}
        value={option}
        onChange={handleChange}
        {...props}
      />
      {error && <StyledError>Error fetching options</StyledError>}
    </div>
  );
};

export default SelectDropdown;

const StyledError = styled(Typography.p)`
  color: red;
`;
