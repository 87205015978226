import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Skeleton } from "@progress/kendo-react-indicators";
import { ColumnField } from "./type";

interface GridSkeletonProps {
  fields: ColumnField[] | undefined;
  height?: string | number;
  rowCount?: number;
}
const GridSkeleton = ({
  fields,
  height,
  rowCount = 100,
}: GridSkeletonProps) => {
  const data = Array.from({ length: rowCount }).map(() => {
    return fields?.reduce((acc: { [key: string]: null }, { field }) => {
      acc[field] = null;
      return acc;
    }, {});
  });
  return (
    <Grid
      data={data}
      rowHeight={50}
      style={{
        maxHeight: height,
        margin: "auto",
        maxWidth: "90%",
        border: 0,
      }}
    >
      <GridToolbar className="k-align-items-flex-end">
        <Skeleton
          style={{ width: "116px", height: "38px", backgroundColor: "#e5e5e3" }}
        />
      </GridToolbar>
      {fields &&
        fields?.map((field) => (
          <GridColumn
            key={field.field}
            width={field.width || "auto"}
            headerCell={() => <Skeleton />}
            headerClassName={"custom-header"}
            cell={() => (
              <td>
                <Skeleton />
              </td>
            )}
          />
        ))}
    </Grid>
  );
};

export default GridSkeleton;
