import { useRef } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import styled from "styled-components";
import { Button } from "@progress/kendo-react-buttons";

interface ErrorListDialogProps {
  errors: string[];
}

export const ErrorListDialog: React.FC<ErrorListDialogProps> = ({ errors }) => {
  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const formattedErrors = errors?.map((err) => ({ error: err }));

  return (
    <div>
      <ExcelExport data={formattedErrors} ref={_export}>
        <StyledGrid data={formattedErrors}>
          <GridToolbar>
            <Button
              themeColor="secondary"
              fillMode="outline"
              onClick={excelExport}
            >
              Export to Excel
            </Button>
          </GridToolbar>
          <GridColumn field="error" title="Errors" />
        </StyledGrid>
      </ExcelExport>
    </div>
  );
};

const StyledGrid = styled(Grid)`
  max-height: 700px;
  overflow: scroll;
`;
